import "/src/css/main.css";

import {Modal, Drawer} from 'flowbite';

$(window).on('load', function () {

    function cookieGet(name) {
        name += "="
        const decodedCookie = decodeURIComponent(document.cookie),
            ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return 0;
    }

    function cookieSet(name, value, attr = {expires: Infinity, path: "/"}) {
        let d = new Date(), expires
        d.setTime(d.getTime() + (attr.expires * 24 * 60 * 60 * 1000))
        expires = d.toUTCString()
        //console.log(`${name}=${value};expires=${expires};path=${attr.path}`)
        document.cookie = `${name}=${value};expires=${expires};path=${attr.path}`
    }


    function cookieRemove(name, attr = {path: "/"}) {
        this.set(name, "", 0, attr.path)
    }

    function cookieGetAll() {
        const ca = decodeURIComponent(document.cookie).split(';'),
            arr = []
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            let item = c.substring(name.length, c.length).split('=')
            arr[item[0]] = item[1]
        }
        return arr
    }


    String.prototype.hashCode = function () {
        var hash = 0, i, chr;
        if (this.length === 0)
            return hash;

        for (i = 0; i < this.length; i++) {
            chr = this.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };

    function closeCallout(calloutID) {
        var calloutElement = $('#' + calloutID);
        calloutElement.addClass('hidden opacity-0');
    }

    function openCallout(calloutID) {
        var calloutElement = $('#' + calloutID);
        calloutElement.removeClass('hidden opacity-0');
    }

    $('a[data-opencallout]').click(function (e) {
        e.preventDefault();

        var calloutID = $(this).data('opencallout');
        openCallout(calloutID);
    });

    $('a[data-closecallout]').click(function (e) {
        e.preventDefault();

        // search for parent
        var calloutParent = $(this).parents("div[data-type='callout']");
        var calloutID = $(calloutParent).attr('id');
        closeCallout(calloutID);
    });


    $("div[data-type='callout']").each(function () {
        var thisEl = $(this),
            thisID = $(this).attr('id');

        closeCallout(thisID); // hide all call outs
        $(this).appendTo('body'); // Move the callout to the bottom of the body

        // //
        // //
        // // // Set vars based on data attributes inside callout
        var delay = thisEl.data('delay'),
            showBasedOnDate = getShowBasedOnDate(thisEl),
            showBasedOnCookie = getShowBasedOnCookie(thisEl),
            trigger = thisEl.data('trigger');

        if (trigger === 'click')
            return;

        if (!showBasedOnDate)
            return;

        if (!showBasedOnCookie)
            return;

        delay = delay ? delay : 0;
        setTimeout(function () {
            // open callout
            openCallout(thisEl.attr('id'));
            setCookieBasedOnShow(thisEl);
        }, delay * 1000);

    });

    $("div[data-type='modal']").each(function () {
        var thisEl = $(this),
            thisID = $(this).attr('id'),
            thisButton = $(this).find('button');

        $(this).appendTo('body'); // Move the modal to the bottom of the body

        //var backdropClasses = 'bg-gray-900/50 fixed inset-0 z-40';
        const options = {
            // placement: 'bottom-right',
            backdrop: 'dynamic',
            backdropClasses:
                'bg-gray-900/50 fixed inset-0 z-40',
            closable: true,
            // onHide: () => {
            //     console.log('modal is hidden');
            // },
            onShow: () => {
                $('div[data-type="modal"]').each(function () {
                    var modalId = $(this).attr('id');
                    if (modalId != thisID) {
                        const $targetOpenModal = document.getElementById(modalId);
                        const modal = new Modal($targetOpenModal);
                        modal.hide();
                    }
                });
            },
            // onToggle: () => {
            //     console.log('modal has been toggled');
            // },
        };

        const instanceOptions = {
            id: thisID,
            override: true
        };

        const $targetModal = document.getElementById(thisID);
        const modal = new Modal($targetModal, options, instanceOptions);
        thisButton.click(function () {
            modal.hide();
        });

        // get the trigger
        $('[data-modal-open="' + thisID + '"]').click(function () {

            modal.toggle();
            // const $targetEl = document.getElementById('offcanvas');
            // const drawer = new Drawer($targetEl);
            // if (drawer.isVisible()) {
            //     console.log('2');
            //     $('div[modal-backdrop]').hide();
            // }
        });

        thisEl.find('[data-close]').click(function () {
            modal.hide();
        });

        var delay = thisEl.data('delay'),
            showBasedOnDate = getShowBasedOnDate(thisEl),
            showBasedOnCookie = getShowBasedOnCookie(thisEl),
            trigger = thisEl.data('trigger');

        if (trigger === 'click')
            return;

        if (!showBasedOnDate)
            return;

        if (!showBasedOnCookie)
            return;

        delay = delay ? delay : 1;
        setTimeout(function () {
            // open modal
            modal.show();
            setCookieBasedOnShow(thisEl);
        }, delay * 1000);

    });


    function getShowBasedOnDate(element) {
        var fromDate = element.data('from'),
            tillDate = element.data('till'),
            today = new Date();

        today.setHours(0, 0, 0, 0);
        if (fromDate) {
            var fromDate = new Date(fromDate);
            fromDate.setHours(0, 0, 0, 0);
        }
        if (tillDate) {
            var tillDate = new Date(tillDate);
            tillDate.setHours(0, 0, 0, 0);
        }

        var showBasedOnDate = true;
        if (fromDate && fromDate > today)
            showBasedOnDate = false;

        if (tillDate && tillDate < today)
            showBasedOnDate = false;

        return showBasedOnDate;
    }

    function getShowBasedOnCookie(element) {
        var show = element.data('show'),
            id = element.attr('id');

        if (show == 'never')
            return;

        var cookieName = 'medotsites-' + String(id + show).hashCode();

        if (!cookieGet(cookieName))
            return true;

        return false;
    }

    function setCookieBasedOnShow(element) {
        var show = element.data('show'),
            id = element.attr('id');

        if (show == 'never')
            return false;

        var cookieName = 'medotsites-' + String(id + show).hashCode();

        var nrOfDays = new Array();
        nrOfDays['once'] = 365;
        nrOfDays['onceday'] = 1;
        nrOfDays['onceweek'] = 7;
        nrOfDays['oncemonth'] = 31;

        if (nrOfDays[show])
            cookieSet(cookieName, 1, {expires: nrOfDays[show]});
    }
});


// GENERAL JS //
// 

// $('#hamburger').click(function () {
//     showOffCanvas();
//
//     $('#offcanvas_close, #offcanvas_mask').click(function () {
//         hideOffCanvas();
//     });
// });
//
// function hideOffCanvas() {
//     $('#offcanvas_panel').addClass('-translate-x-full');
//     $('#offcanvas_mask').addClass('-translate-x-full');
//     $('#offcanvas_close').hide();
// }
//
// function showOffCanvas() {
//     $('#offcanvas_mask').show();
//     setTimeout(function () {
//         $('#offcanvas_mask').removeClass('-translate-x-full');
//         setTimeout(function () {
//             $('#offcanvas_close').show();
//             $('#offcanvas_panel').removeClass('-translate-x-full');
//         }, 100);
//     }, 300);
// }

// function showSpinner(msg) {
//     if (msg) {
//         $('#mask_spinner_spinner_text').text(msg);
//     }
// 
//     $('#mask_spinner').show();
//     $('#mask_spinner_spinner').show();
// }


// STRIPE //
$(window).on('load', function () {
    //var stripeKey = $('script#stripepublishkey').text();
    //var stripe = Stripe(stripeKey, {'apiVersion': '2020-08-27'});
});


